<template>
  <div class="side-group">
    <div class="side-tit">
      <div class="side-tit-item">
        <span :class="{ active: active === 1 }" @click="tabChange(1)">{{
          $t("bbs.my_circle")
        }}</span>
        <!-- 我的圈子 -->
      </div>
      <div class="side-tit-item">
        <span :class="{ active: active === 2 }" @click="tabChange(2)">{{
          $t("bbs.find_circle")
        }}</span>
        <!-- 发现圈子 -->
      </div>
    </div>
    <div
      class="side-group-btns"
      v-if="active === 1 && communityConfig.isGroup == 1"
    >
      <button @click="addGroup">+ {{ $t("bbs.create_circles") }}</button>
      <!-- 创建学习圈 -->
    </div>
    <a-spin :spinning="loading">
      <div class="side-group-list">
        <template v-if="active === 1">
          <div
            class="side-group-item"
            v-for="item in list"
            :key="item.groupId"
            @click="jump(item)"
          >
            <div class="cover">
              <a-avatar :size="56" shape="square" :src="item.coverImg">
                {{ item.groupName }}
              </a-avatar>
            </div>
            <div class="info">
              <h5>{{ item.groupName }}</h5>
              <div>
                <tag
                  text="我创建的"
                  v-if="item.masterUid === userInfo.userId"
                />
                <tag
                  text="我管理的"
                  v-if="item.adminUids.includes(userInfo.userId)"
                />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div
            class="side-group-item"
            v-for="item in list"
            :key="item.groupId"
            @click="jump(item)"
          >
            <div class="cover">
              <a-avatar :size="56" shape="square" :src="item.coverImg">
                {{ item.groupName }}
              </a-avatar>
            </div>
            <div class="info">
              <h5>{{ item.groupName }}</h5>
              <div>
                <div class="name">
                  <OpenData type="userName" :openid="item.masterName" />
                </div>
                <div class="count">
                  <!-- 主题 -->
                  <span>{{ item.subjects }} {{ $t("bbs.topic") }}</span
                  ><i></i><span>{{ item.users }} {{ $t("bbs.member") }}</span>
                  <!-- 成员 -->
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="side-page" v-if="totals">
          <a-pagination
            hideOnSinglePage
            size="small"
            simple
            :defaultPageSize="10"
            v-model:current="page"
            :total="totals"
            @change="pageChange"
          />
        </div>
        <a-empty v-if="!totals" />
      </div>
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { getCommunityConfig, getGroupList } from "@/api/bbs";
import tag from "./tag.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "sideGroup",
  components: {
    tag,
    OpenData,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    newWindow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["addGroup"],
  setup(props, { emit }) {
    const router = useRouter();
    const state = reactive({
      communityConfig: {},
      active: 1,
      loading: false,
      page: 1,
      list: [],
      totals: 0,
    });

    getCommunityConfig().then((res) => {
      state.communityConfig = res.data;
    });

    const getList = () => {
      state.loading = true;
      getGroupList({
        page: state.page,
        pageSize: 10,
        type: state.active,
      }).then((res) => {
        state.loading = false;
        state.list = res.data.list || [];
        state.totals = res.data.totals || 0;
      });
    };

    const init = () => {
      state.page = 1;
      getList();
    };
    init();

    const tabChange = (active) => {
      state.active = active;
      state.page = 1;
      getList();
    };

    const pageChange = (n) => {
      state.page = n;
      getList();
    };

    const addGroup = () => {
      emit("addGroup");
    };

    const jump = (item) => {
      if (props.newWindow) {
        window.open(`/bbs/group?ddtab=true&groupId=${item.groupId}`);
      } else {
        router.push({
          path: "/bbs/group",
          query: {
            groupId: item.groupId,
          },
        });
      }
    };

    return {
      ...toRefs(state),
      init,
      tabChange,
      pageChange,
      addGroup,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.side-tit {
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 24px;
  .mixinFlex(space-between);
  &-item {
    width: 50%;
    font-size: 16px;
    color: #202020;
    text-align: center;
    span {
      display: inline-block;
      padding: 5px 10px;
      position: relative;
      cursor: pointer;
      transition: color 0.3s;
      &:after {
        content: "";
        background-color: @color-theme;
        position: absolute;
        bottom: -11px;
        left: 50%;
        right: 50%;
        height: 2px;
        transition: all 0.3s;
      }
      &:hover {
        color: @color-theme;
      }
      &.active {
        color: @color-theme;
        &:after {
          left: 10px;
          right: 10px;
        }
      }
    }
  }
}
.side-group {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  &-btns {
    padding: 20px 20px 0 20px;
    button {
      width: 100%;
      border: 1px solid @color-theme;
      background-color: #fff;
      color: @color-theme;
      line-height: 38px;
      font-size: 14px;
      text-align: center;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }
  &-list {
    padding: 15px 20px;
  }
  &-item {
    margin-bottom: 15px;
    cursor: pointer;
    .mixinFlex(space-between);
    .cover {
      width: 56px;
      height: 56px;
      border-radius: 4px;
      overflow: hidden;
    }
    .info {
      width: calc(100% - 64px);
      .mixinFlex(space-between; unset; column);
      h5 {
        color: #333;
        font-size: 14px;
        .mixinEllipsis(20px);
        margin: 0;
        transition: color 0.3s;
      }
      .name {
        color: #999;
        font-size: 12px;
        .mixinEllipsis(16px);
      }
      .count {
        line-height: 16px;
        .mixinFlex(flex-start; center);
        span {
          color: #999;
          font-size: 12px;
        }
        i {
          width: 1px;
          height: 10px;
          background-color: rgba(0, 0, 0, 0.15);
          margin: 0 10px;
        }
      }
    }
    &:hover {
      h5 {
        color: @color-theme;
      }
    }
  }
}
.side-page {
  text-align: center;
  ::v-deep(.ant-pagination) {
    .ant-pagination-item-link {
      background-color: #f3f3f3;
      border-radius: 50%;
    }
    .ant-pagination-simple-pager {
      margin-right: 20px;
      input {
        border: none;
        padding: 0;
        margin: 0;
        text-align: right;
        pointer-events: none;
      }
    }
    .ant-pagination-slash {
      margin: 0 2px;
    }
  }
}
</style>
